var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collection"},[_c('div',{staticClass:"container"},[_vm._t("default")],2),(_vm.hasFilterTags)?_c('tag-filter-list',{staticClass:"container px-2 pt-2",attrs:{"tags":_vm.filterTags,"with-header":false},on:{"updated":_vm.selectFilterTags}}):_vm._e(),_c('div',{class:[{ '--scroll-x': _vm.hasOverflowX }, 'collection__content']},[_c('div',{class:[
        { '--video-fullscreen': _vm.isVideoFullscreen },
        'collection__items'
      ]},[_c('div',{staticClass:"collection__items-inner",style:({
          padding: _vm.collectionItemsPadding,
          'grid-template-columns': ("repeat(" + (_vm.itemColumns ||
            'auto-fit') + ", 1fr)"),
          'grid-template-rows': ("repeat(" + _vm.itemRows + ", min-content)"),
          'grid-auto-flow': _vm.flow,
          width: _vm.gridWidth,
          maxWidth: _vm.gridMaxWidth
        })},[(_vm.fetchedItems.length > 0)?[_vm._l((_vm.fetchedItems),function(item){return _c('div',{key:_vm.itemKey(item),staticClass:"collection__items-cell"},[_c('collection-item',{attrs:{"page-id":item.type === 'page' ? item.data.id : item.data.page_id,"title":item.data.title,"type":item.type,"content-type":item.content_type,"image":item.data.banner,"video":item.data.video,"product":item.data.product,"to":item.data.path,"card-style":_vm.cardStyle,"card-display":_vm.cardDisplay,"show-price":_vm.showPrice,"show-time":_vm.showTime,"show-progress":_vm.showItemProgress(item),"show-booked":_vm.showItemBooked(item),"item-data":item.data,"modifiers":_vm.modifiers,"image-size":_vm.cardImageSize,"invert-colors":_vm.invertColors,"unlock":_vm.unlock,"current-schedule":item.current_schedule}})],1)}),(_vm.isDynamic && _vm.totalCount > _vm.fetchedItems.length)?_c('div',{staticClass:"collection__items-cell",attrs:{"id":_vm.collectionLoaderId}},[_c('div',{staticClass:"p-4 h-100 d-flex align-items-center"},[_c('v-button',{attrs:{"small":"","ghost":"","block":"","is-loading":_vm.isLoading},on:{"click":function () { return _vm.handleShowMore(); }}},[_vm._v(" "+_vm._s(_vm.$t("components.collection.showMore"))+" ")])],1)]):_vm._e()]:(
            _vm.isLoading ||
              !((_vm.isDynamic && !_vm.isBuilderContext()) || _vm.hasSelectedFilterTags)
          )?_vm._l((_vm.placeHolderNumber),function(index){return _c('div',{key:index,staticClass:"collection__items-cell --placeholder"},[_c('collection-item',{attrs:{"is-loading":_vm.isLoading,"card-style":_vm.cardStyle,"card-display":_vm.cardDisplay}})],1)}):_vm._e()],2)])]),(
      !_vm.isBuilderContext() &&
        ((_vm.isDynamic && _vm.collection.dynamic_offset === 0) ||
          _vm.hasSelectedFilterTags) &&
        !_vm.isLoading &&
        _vm.fetchedItems.length === 0
    )?[_c('div',{staticClass:"text-center pb-5"},[(_vm.collection.subscriber_property)?[(_vm.propertyValue)?[_c('div',{staticClass:"opacity-40"},[_vm._v(" "+_vm._s(_vm.$t("components.collection.noResult"))+" "),_c('v-link',{staticClass:"text-underline",attrs:{"to":"/me/account/details"}},[_vm._v(" "+_vm._s(_vm.collection.subscriber_property.name)+" ")])],1)]:[_c('p',{staticClass:"opacity-40"},[_vm._v(" "+_vm._s(_vm.$t("components.collection.updateSettingTitle"))+" ")]),(_vm.isAuthenticated)?_c('v-button',{attrs:{"secondary":"","small":"","is-loading":_vm.isLoadingProperty},on:{"click":function () { return _vm.handlePropertyClick(_vm.collection.subscriber_property.slug); }}},[_vm._v(" "+_vm._s(_vm.$t("components.collection.updateNow"))+" ")]):_c('v-button',{attrs:{"secondary":"","small":""},on:{"click":_vm.triggerAuthModal}},[_vm._v(" "+_vm._s(_vm.$t("components.collection.signIn"))+" ")])]]:(_vm.isDynamic)?_c('span',{staticClass:"text-muted"},[[_vm._v(" "+_vm._s(_vm.dynamicContentTypeText == "any" ? _vm.$t("components.collection.noAnyContentFound") : _vm.$t("components.collection.noFoundContent", { dynamicContentTypeText: _vm.pluralize(_vm.dynamicContentTypeText, 2) }))+" ")]],2):_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("components.collection.noAnyContentFound"))+" ")])],2),(_vm.editingProperty)?_c('edit-subscriber-property-modal',{attrs:{"editing-property":_vm.editingProperty},on:{"close":_vm.closePropertyModal}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }