import ApiClient from "@/lib/api-client"
import { get, set, LS_KEYS } from "@/lib/local-storage"
import { WORKOUT_MODES as WORKOUT_SESSION_MODES } from "@shared/workout"

export const WORKOUT_TYPES = {
  BEEP_TEST: "beep_test",
  VIDEO_WORKOUT: "video_workout"
}

export { WORKOUT_SESSION_MODES }

export const WORKOUT_MODE_DEFAULT = WORKOUT_SESSION_MODES.FOLLOW_ALONG
export const DEFAULT_IS_MUTED = true

export default {
  namespaced: true,

  state: {
    workoutModePreference:
      get(LS_KEYS.WORKOUT_MODE_PREFERENCE) || WORKOUT_MODE_DEFAULT,

    isMuted: get(LS_KEYS.WORKOUTS_MUTED)
      ? get(LS_KEYS.WORKOUTS_MUTED) === "true"
      : DEFAULT_IS_MUTED,

    isFollowAlongVisible: false
  },

  mutations: {
    setWorkoutModePreference(state, preference) {
      if (!Object.values(WORKOUT_SESSION_MODES).includes(preference)) {
        throw "Invalid Preference"
      }

      set(LS_KEYS.WORKOUT_MODE_PREFERENCE, preference)
      state.workoutModePreference = preference
    },

    setWorkoutsMuted(state, isMuted) {
      set(LS_KEYS.WORKOUTS_MUTED, isMuted)
      state.isMuted = isMuted
    },

    setIsFollowAlongVisible(state, isFollowAlongVisible) {
      state.isFollowAlongVisible = isFollowAlongVisible
    }
  },

  actions: {
    startSession(_, { workoutId, mode }) {
      return ApiClient.post(`/workouts/${workoutId}/session`, {
        session_mode: mode
      }).then(res => res.data.session)
    },

    completeSession(_, { workoutId, sessionId }) {
      return ApiClient.put(`/workouts/${workoutId}/session/${sessionId}`).then(
        res => res.data.session
      )
    },

    getWorkoutSessions(_, workoutId) {
      return ApiClient.get(`/workouts/${workoutId}/sessions`).then(
        res => res.data
      )
    },

    recordSessionActivity(
      _,
      { workoutId, sessionId, groupId, itemId, activityType, data }
    ) {
      return ApiClient.post(
        `/workouts/${workoutId}/session/${sessionId}/activity`,
        {
          workout_group_id: groupId,
          workout_group_item_id: itemId,
          activity_type: activityType,
          data: data || {}
        }
      ).then(res => res.data.session)
    }
  },

  getters: {
    areWorkoutsMuted(state) {
      return state.isMuted
    },

    workoutIsFollowAlongVisible(state) {
      return state.isFollowAlongVisible
    }
  }
}
