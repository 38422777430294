<template>
  <div class="collection">
    <div class="container">
      <slot />
    </div>

    <tag-filter-list
      v-if="hasFilterTags"
      :tags="filterTags"
      :with-header="false"
      class="container px-2 pt-2"
      @updated="selectFilterTags"
    />

    <div :class="[{ '--scroll-x': hasOverflowX }, 'collection__content']">
      <div
        :class="[
          { '--video-fullscreen': isVideoFullscreen },
          'collection__items'
        ]"
      >
        <div
          class="collection__items-inner"
          :style="{
            padding: collectionItemsPadding,
            'grid-template-columns': `repeat(${itemColumns ||
              'auto-fit'}, 1fr)`,
            'grid-template-rows': `repeat(${itemRows}, min-content)`,
            'grid-auto-flow': flow,
            width: gridWidth,
            maxWidth: gridMaxWidth
          }"
        >
          <template v-if="fetchedItems.length > 0">
            <div
              v-for="item in fetchedItems"
              :key="itemKey(item)"
              class="collection__items-cell"
            >
              <collection-item
                :page-id="
                  item.type === 'page' ? item.data.id : item.data.page_id
                "
                :title="item.data.title"
                :type="item.type"
                :content-type="item.content_type"
                :image="item.data.banner"
                :video="item.data.video"
                :product="item.data.product"
                :to="item.data.path"
                :card-style="cardStyle"
                :card-display="cardDisplay"
                :show-price="showPrice"
                :show-time="showTime"
                :show-progress="showItemProgress(item)"
                :show-booked="showItemBooked(item)"
                :item-data="item.data"
                :modifiers="modifiers"
                :image-size="cardImageSize"
                :invert-colors="invertColors"
                :unlock="unlock"
                :current-schedule="item.current_schedule"
              />
            </div>
            <div
              v-if="isDynamic && totalCount > fetchedItems.length"
              :id="collectionLoaderId"
              class="collection__items-cell"
            >
              <div class="p-4 h-100 d-flex align-items-center">
                <v-button
                  small
                  ghost
                  block
                  :is-loading="isLoading"
                  @click="() => handleShowMore()"
                >
                  {{ $t("components.collection.showMore") }}
                </v-button>
              </div>
            </div>
          </template>
          <template
            v-else-if="
              isLoading ||
                !((isDynamic && !isBuilderContext()) || hasSelectedFilterTags)
            "
          >
            <div
              v-for="index in placeHolderNumber"
              :key="index"
              class="collection__items-cell --placeholder"
            >
              <collection-item
                :is-loading="isLoading"
                :card-style="cardStyle"
                :card-display="cardDisplay"
              />
            </div>
          </template>
        </div>
      </div>
    </div>

    <template
      v-if="
        !isBuilderContext() &&
          ((isDynamic && collection.dynamic_offset === 0) ||
            hasSelectedFilterTags) &&
          !isLoading &&
          fetchedItems.length === 0
      "
    >
      <div class="text-center pb-5">
        <template v-if="collection.subscriber_property">
          <template v-if="propertyValue">
            <div class="opacity-40">
              {{ $t("components.collection.noResult") }}
              <v-link class="text-underline" to="/me/account/details">
                {{ collection.subscriber_property.name }}
              </v-link>
            </div>
          </template>

          <template v-else>
            <p class="opacity-40">
              {{ $t("components.collection.updateSettingTitle") }}
            </p>

            <v-button
              v-if="isAuthenticated"
              secondary
              small
              :is-loading="isLoadingProperty"
              @click="
                () => handlePropertyClick(collection.subscriber_property.slug)
              "
            >
              {{ $t("components.collection.updateNow") }}
            </v-button>
            <v-button v-else secondary small @click="triggerAuthModal">
              {{ $t("components.collection.signIn") }}
            </v-button>
          </template>
        </template>

        <span v-else-if="isDynamic" class="text-muted">
          <template>
            {{
              dynamicContentTypeText == "any"
                ? $t("components.collection.noAnyContentFound")
                : $t("components.collection.noFoundContent", {
                    dynamicContentTypeText: pluralize(dynamicContentTypeText, 2)
                  })
            }}
          </template>
        </span>

        <span v-else class="text-muted">
          {{ $t("components.collection.noAnyContentFound") }}
        </span>
      </div>

      <edit-subscriber-property-modal
        v-if="editingProperty"
        :editing-property="editingProperty"
        @close="closePropertyModal"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex"

import { isPresent, pluralize, isBuilderContext } from "@/lib/utils"
import enumValidator from "@/lib/validators/enum-validator"
import { withRatio, toPadding } from "@shared/block-ratio"
import stringEnumValidator from "@/lib/validators/string-enum-validator"
import { addToHash, removeFromHash } from "@/lib/url-helpers"
import isEqual from "lodash/isEqual"

import CollectionItem from "@/components/CollectionItem"
import EditSubscriberPropertyModal, {
  PROPERTY_MODAL
} from "@/components/modals/EditSubscriberPropertyModal"

const HORIZONTAL = "horizontal"
const VERTICAL = "vertical"
export const HORIZONTAL_MAX_WIDTH = 320

export default {
  components: {
    CollectionItem,
    EditSubscriberPropertyModal,
    TagFilterList: () => import("@/components/search/TagFilterList")
  },

  props: {
    collection: {
      type: Object,
      default: () => ({})
    },
    direction: {
      type: String,
      default: VERTICAL,
      validator: enumValidator([HORIZONTAL, VERTICAL])
    },
    cardDisplay: {
      type: String,
      default: VERTICAL,
      validator: enumValidator([HORIZONTAL, VERTICAL])
    },
    cardStyle: {
      type: String,
      default: "raised",
      validator: enumValidator(["flat", "raised"])
    },
    cardImageSize: {
      type: Number,
      default: 100
    },
    columns: {
      type: Number,
      default: null
    },
    rows: {
      type: Number,
      default: null
    },
    columnWidthRatio: {
      type: Number,
      default: 100
    },
    spaceXRatio: {
      type: Number,
      default: 100
    },
    spaceTopRatio: {
      type: Number,
      default: 100
    },
    spaceBottomRatio: {
      type: Number,
      default: 100
    },
    modifiers: {
      type: String,
      default: "",
      validator: stringEnumValidator(["date-card", "no-image"])
    },
    pageId: { type: [String, Number], default: null },
    items: { type: Array, default: () => [] },
    unlock: { type: Boolean, default: null },
    showPrice: { type: Boolean, default: true },
    showTime: { type: Boolean, default: true },
    showProgress: { type: Boolean, default: true },
    showBooked: { type: Boolean, default: false },
    invertColors: { type: Boolean, default: false }
  },

  data() {
    return {
      isLoading: false,
      dynamicItems: [],
      debounce: null,
      editingProperty: null,
      isLoadingProperty: false,
      currentPage: 1,
      totalCount: 0,
      observer: null,
      selectedFilterTags: [],
      HORIZONTAL
    }
  },

  watch: {
    collection(newVal, oldVal) {
      if (isEqual(newVal, oldVal)) return

      this.resetDynamicData()
      clearTimeout(this.debounce)

      this.debounce = setTimeout(() => {
        if (this.isDynamic) {
          if (!this.dynamicContentType) {
            this.dynamicItems = []

            return
          }

          this.fetchDynamicContent()
        }
      }, 300)
    },

    propertyValue() {
      if (this.shouldFetchDynamicContent) {
        this.fetchDynamicContent()
      }
    },

    isDynamic() {
      if (!this.isDynamic) {
        this.totalCount = 0
      }
    },

    isAuthenticated() {
      // Ensure content is reloaded after logout
      if (this.shouldFetchDynamicContent && !this.isAuthenticated) {
        this.resetAndFetchDynamicContent()
      }
    },

    hasCheckedAuthentication: {
      immediate: true,
      handler: function() {
        if (this.shouldFetchDynamicContent && this.hasCheckedAuthentication) {
          this.fetchDynamicContent()
        }
      }
    }
  },

  computed: {
    ...mapState("videos", { isVideoFullscreen: "isFullscreen" }),
    ...mapState("subscriberProperties", ["submittedProperties"]),
    ...mapGetters(["account"]),
    ...mapGetters("auth", [
      "isAuthenticated",
      "currentUser",
      "hasCheckedAuthentication"
    ]),

    propertyValue() {
      return this.isDynamic &&
        this.dynamicContentType &&
        this.propertySlug &&
        this.submittedProperties
        ? this.submittedProperties[this.propertySlug]
        : null
    },

    isDynamic() {
      return this.collection.is_dynamic && isPresent(this.dynamicContentType)
    },

    dynamicContentType() {
      return this.collection.dynamic_content_type
    },

    shouldFetchDynamicContent() {
      return this.isDynamic && this.dynamicContentType
    },

    dynamicContentTypeText() {
      return this.dynamicContentType &&
        this.dynamicContentType.startsWith("dynamic_")
        ? this.$t("components.collection.pages")
        : this.dynamicContentType
    },

    filterTags() {
      return this.collection ? this.collection.filter_tags : []
    },

    filteredItems() {
      return this.selectedFilterTags.length > 0
        ? this.items.filter(
            item =>
              item.tags &&
              this.selectedFilterTags.some(tag => item.tags.includes(tag))
          )
        : this.items
    },

    fetchedItems() {
      return this.isDynamic ? this.dynamicItems : this.filteredItems
    },

    gridWidth() {
      const horizontalSpacing =
        (this.spaceXRatio * 0.01 - 0.5) * Math.max(0, this.itemColumns - 2)

      return `calc(${this.itemColumns *
        this.columnWidthRatio}% - ${horizontalSpacing}rem)`
    },

    gridMaxWidth() {
      return this.direction === HORIZONTAL
        ? `${this.itemColumns * HORIZONTAL_MAX_WIDTH}px`
        : null
    },

    cellWidth() {
      return withRatio(100, this.columnWidthRatio, { unit: "%" })
    },

    placeHolderNumber() {
      return this.itemColumns * this.itemRows
    },

    hasItems() {
      return this.fetchedItems.length > 0
    },

    hasFilterTags() {
      return this.filterTags?.length > 0
    },

    hasSelectedFilterTags() {
      return this.selectedFilterTags.length > 0
    },

    collectionItemsPadding() {
      return toPadding(
        this.spaceTopRatio,
        this.spaceXRatio,
        this.spaceBottomRatio
      )
    },

    showLoadMoreButton() {
      return this.isDynamic && this.totalCount > this.fetchedItems.length
    },

    itemColumns() {
      if (!this.hasItems) {
        if (this.columns) {
          return this.columns
        } else if (this.rows) {
          return 1
        } else {
          return this.direction === HORIZONTAL ? 2 : 1
        }
      }

      if (this.columns && this.rows && this.direction === HORIZONTAL) {
        const r = this.rows
        return (
          this.columns +
          Math.ceil(
            Math.max(this.fetchedItems.length - r * this.columns, 0) / r
          )
        )
      }

      if (this.columns) {
        return this.columns
      } else if (this.rows) {
        return Math.ceil(this.fetchedItems.length / this.rows)
      } else {
        return this.direction === HORIZONTAL
          ? (this.fetchedItems.length || 1) + (this.showLoadMoreButton ? 1 : 0)
          : 1
      }
    },

    itemRows() {
      if (!this.hasItems) {
        if (this.rows) {
          return this.rows
        } else {
          return 1
        }
      }

      if (this.rows && this.columns && this.direction === VERTICAL) {
        const c = this.columns
        return (
          this.rows +
          Math.ceil(Math.max(this.fetchedItems.length - c * this.rows, 0) / c)
        )
      }

      if (this.rows) {
        return this.rows
      } else if (this.columns) {
        return Math.ceil(this.fetchedItems.length / this.columns)
      } else {
        return this.direction === HORIZONTAL
          ? 1
          : (this.fetchedItems.length || 1) + (this.showLoadMoreButton ? 1 : 0)
      }
    },

    flow() {
      return this.direction === HORIZONTAL ? "row" : "column"
    },

    hasOverflowX() {
      return !(
        (this.direction === VERTICAL && this.itemColumns === 1) ||
        this.fetchedItems.length === 1 ||
        (this.itemColumns === 2 && this.columnWidthRatio === 50)
      )
    },

    propertySlug() {
      return this.collection.subscriber_property?.slug
    },

    collectionLoaderId() {
      return `collection-loader-${this._uid}`
    }
  },

  methods: {
    ...mapActions("content", ["fetchItems"]),
    ...mapActions("subscriberProperties", ["fetchProperty"]),

    pluralize,
    isBuilderContext,

    fetchDynamicContent() {
      if (!this.hasCheckedAuthentication) return

      this.isLoading = true

      this.fetchItems({
        contentType: this.collection.dynamic_content_type,
        duration: this.collection.dynamic_duration_seconds,
        dynamicDirection: this.collection.dynamic_direction,
        includeCurrent: this.collection.dynamic_include_current,
        offset: this.collection.dynamic_offset,
        limit: this.collection.dynamic_limit,
        withTags: this.collection.tags.map(t => t.text),
        filterTags: this.selectedFilterTags,
        withSubscriberProperty: this.propertySlug,
        orderBy: this.collection.dynamic_order_by,
        withAllTags: this.collection.dynamic_with_all_tags,
        progress: this.collection.dynamic_progress,
        scheduleType: this.collection.dynamic_schedule_type,
        page: this.currentPage,
        pageId: this.pageId,
        timezone:
          this.collection.is_dynamic_user_timezone &&
          isPresent(this.currentUser)
            ? this.currentUser?.timezone
            : null
      })
        .then(data => {
          this.dynamicItems = [
            ...new Map(
              [...this.dynamicItems, ...data.items].map(i => [i.key, i])
            ).values()
          ]

          this.totalCount = data.total_count

          this.setupInfinitePagination()
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    selectFilterTags(tags) {
      this.selectedFilterTags = tags

      if (this.isDynamic) {
        this.resetAndFetchDynamicContent()
      }
    },

    resetAndFetchDynamicContent() {
      this.resetDynamicData()
      this.fetchDynamicContent()
    },

    resetDynamicData() {
      this.currentPage = 1
      this.dynamicItems = []
    },

    showItemProgress(item) {
      return this.showProgress && !!item.show_progress
    },

    showItemBooked(item) {
      return this.showBooked && !!item.show_booked
    },

    showFreeBadge(item) {
      if (this.hidePrice) return false

      switch (item.type) {
        case "program":
          return item.data.is_free && item.data.show_is_free
        case "event_occurrence":
          return !item.data.is_paid
        default:
          return false
      }
    },

    itemKey(item) {
      return `${item.content_type}-${item.uuid || item.data.id}`
    },

    triggerAuthModal() {
      this.$router.push("#auth")
    },

    handlePropertyClick(slug) {
      this.isLoadingProperty = true

      this.fetchProperty(slug)
        .then(property => {
          this.editingProperty = property
          this.$router.push(addToHash(this.$route.hash, PROPERTY_MODAL))
        })
        .finally(() => {
          this.isLoadingProperty = false
        })
    },

    closePropertyModal() {
      this.editingProperty = null
      this.$router.push(removeFromHash(this.$route.hash, PROPERTY_MODAL))
    },

    handleShowMore() {
      this.currentPage += 1
      this.fetchDynamicContent()
    },

    setupInfinitePagination() {
      if (isBuilderContext()) return
      if (!this.showLoadMoreButton) return
      if (this.observer) return

      this.$nextTick(() => {
        this.observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            this.handleShowMore()
          }
        })

        const el = document.getElementById(this.collectionLoaderId)

        if (el) {
          this.observer.observe(el)
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.collection {
  overflow-x: hidden;

  .text-underline {
    text-decoration: underline !important;
  }
}

.collection__link {
  &.v-button.--small {
    margin-left: $size--2;
    margin-top: -$size--4;

    .v-icon {
      transform: scale(1.4);
      margin-left: $size--2;
    }
  }
}

.collection__content {
  @include container;

  &.--scroll-x {
    @include hide-scroll-bars;

    margin: initial;
    max-width: 100%;
    overflow-x: auto;
    padding-left: calc((100% - #{$content--max-width}) / 2);
  }
}

.collection__items {
  .collection__items-inner {
    column-gap: $size--4;
    row-gap: $size--4;
    display: grid;
  }

  .collection__items-cell {
    flex-shrink: 0;

    &.--placeholder {
      .collection-item {
        animation: none;
        opacity: 1;
      }
    }
  }

  &.--video-fullscreen {
    overflow: visible !important;
  }
}
</style>
