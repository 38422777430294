import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {
    pastDueSubscriptions: []
  },

  mutations: {
    setPastDueSubscriptions(state, subscriptions) {
      state.pastDueSubscriptions = subscriptions
    }
  },

  actions: {
    purchaseIap(
      _,
      { productId, transactionId, originalTransactionId, email = null }
    ) {
      return ApiClient.post(`/products/${productId}/purchase/iap`, {
        transaction_id: transactionId,
        original_transaction_id: originalTransactionId,
        email: email
      }).then(res => res.data)
    },

    fetchIntent(
      _,
      {
        productId,
        versionId = undefined,
        coupon = null,
        referral = null,
        orderBumps = [],
        address = {},
        name = null,
        finalAmount = null
      }
    ) {
      return ApiClient.post(`/products/${productId}/intent`, {
        version_id: versionId,
        coupon,
        referral,
        order_bumps: orderBumps,
        address,
        name,
        final_amount: finalAmount
      }).then(res => res.data)
    },

    fetchProduct(_, id) {
      return ApiClient.get(`/products/${id}`).then(res => {
        return res.data
      })
    },

    fetchMultipleProducts(_, ids) {
      return ApiClient.get(`/products/list`, { params: { ids } }).then(res => {
        return res.data.products
      })
    },

    hasActivePurchase(_, id) {
      return ApiClient.get(`/products/${id}/has_active_purchase`).then(res => {
        return res.data.has_active_purchase
      })
    },

    previewOrder(
      _,
      {
        productId,
        couponCode,
        orderBumps,
        versionId = undefined,
        address = null
      }
    ) {
      return ApiClient.post(`/products/${productId}/preview`, {
        coupon_code: couponCode,
        order_bumps: orderBumps,
        version_id: versionId,
        address
      }).then(res => res.data)
    },

    paySubscription(_, id) {
      return ApiClient.post(`/subscriptions/${id}/pay`)
    },

    fetchPurchasedItems(_, productId) {
      return ApiClient.get(`/products/${productId}/purchased_items`).then(
        res => {
          return res.data
        }
      )
    }
  },

  getters: {
    getAppProducts(_, __, state) {
      return state.global?.access.app_products || []
    },

    hasPastDueSubscription(state) {
      return state.pastDueSubscriptions.length > 0
    },

    hasPastDueSubscriptionForProduct(state) {
      return productId =>
        state.pastDueSubscriptions.some(sub => sub.product_id === productId)
    },

    getPastDueSubscriptionForProduct(state) {
      return productId =>
        state.pastDueSubscriptions.find(sub => sub.product_id === productId)
    }
  }
}
